<template>
  <div class="info-wrapper">
    <p-header></p-header>
    <div class="banner">
      <img src="~common/images/info_top.png" width="100%" alt="">
      <div class="banner-title">稿真无忧隐私政策</div>
    </div>
    <div class="info-container">
      <div class="info-content-box">
        <div class="info-item-box">
          <!-- <p class="text" style="text-align:center;">稿真无忧隐私政策</p> -->
          <p class="subtext">稿真无忧平台（网址：【http://saas.gzwy.cc】，以下简称“本平台”）由北京创新一点通数码科技有限公司（以下简称“一点通”或者“我们”）运营。我们的注册地址为：北京市朝阳区酒仙桥路14号50号楼A1区4门2层127室。本政策适用于本平台面向企业及企业经办人（企业经办人包括企业员工、供应商经办人等个人，以下统称“您”）提供的产品及服务。您使用本平台，我们可能会处理（包括收集、使用、存储、加工、传输、提供、公开、删除等）您的相关信息。我们深知信息对您的重要性，因此我们会竭尽全力尊重并保护您的信息安全可靠，我们致力于维持您对我们的信任，保护您的信息。同时，我们承诺，我们将按业界成熟的安全标准，采取相应的安全保护措施来保护您的信息。请您在向我们提交数据之前，阅读并了解《稿真无忧隐私政策》（简称“本政策”）。在您访问和使用我们提供的服务时，我们将按照本政策收集、处理及披露您的信息。</p>
          <p class="subtext">在您使用本平台之前，请您务必仔细阅读本政策全部内容并充分了解本政策条款内容，包括但不限于免除或限制我们责任的条款，在确认充分理解并同意后再提供您的个人信息。如您对本政策有任何疑问，请联系我们。您使用或在我们更新本政策后继续使用我们的产品或服务，即意味着您同意本政策(含更新版本)内容，并且同意我们按照本政策处理您的相关信息。如您不同意本政策或其中的任何条款，您可以选择停止使用或注销稿真无忧平台账号，同时我们将无法再为您提供相关服务。
            <br/>最近更新日期：【2023年2月1日】 ；生效日期【2023年2月1日】。
            <br/>如果您有任何疑问、意见或建议，请通过以下联系方式与我们联系： 
            <br/>电子邮件：【pdm.service@greatdata.com.cn】
            <br/>电 话：【+86 10 6435 6368-1702】</p>

          <p class="subtext">本政策将帮助您了解以下内容：
          <br/>一、我们如何收集和使用您的个人信息
          <br/>二、我们如何使用Cookie和同类技术
          <br/>三、我们如何共享、转让、公开披露您的个人信息
          <br/>四、您主动与他人共享的信息
          <br/>五、我们如何存储个人信息
          <br/>六、我们如何保护个人信息的安全
          <br/>七、您如何访问和管理您的个人信息
          <br/>八、本隐私政策的修订
          <br/>九、联系我们</p>

          <p class="subtext">一．我们如何收集和使用您的个人信息 </p>
          <p class="subtext">个人信息，指以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。</p>
          <p class="subtext">敏感个人信息，指一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息，包括生物识别、宗教信仰、特定身份、医疗健康、金融账户、行踪轨迹等信息，以及不满十四周岁未成年人的个人信息。</p>
          <p class="subtext">（一）我们如何收集您的个人信息</p>
          <p class="subtext">我们会按照如下方式收集您在使用服务时主动提供的，以及通过自动化手段收集您在使用功能或接受服务过程中产生的信息：</p>
          <p class="subtext">1.1 平台使用 </p>
          <p class="subtext">1.1.1申请账户、登录 </p>
          <p class="subtext">本平台不提供注册功能。当您申请账户（包括企业账户和单个用户账户）、登录本平台时，需要您提供电子邮箱、姓名、电话、企业名称、统一社会信用代码以及部门信息，收集这些信息是为了帮助您完成申请账户及后续使用。申请账户时向您获取的手机号码是履行国家法律法规关于网络实名制要求的必要信息。密码将通过邮箱发送给您。</p>
          <p class="subtext">1.1.2 业务咨询</p>
          <p class="subtext">当您通过本平台进行业务咨询时，需要您提供电子邮箱、姓名、电话、职位、企业以及部门信息，收集这些信息是为了帮助您完成业务咨询。用于业务咨询的用户信息将用于一点通及其授权售卖渠道业务人员与您取得联系，为您介绍、解答本平台相关信息。</p>
          <p class="subtext">1.1.3 帐号找回 </p>
          <p class="subtext">您可以通过您申请账户时填写的邮箱对帐号进行找回。 </p>
          <p class="subtext">1.2 页面浏览</p>
          <p class="subtext">1.2.1 日志信息</p>
          <p class="subtext">我们可能会抓取您浏览网页的时间、行为，收集相关日志信息，以便为您提供更好的服务。您可以通过浏览器设置关闭cookie来拒绝这些信息的收集，这样有可能会影响到部分功能的使用。</p>
          <p class="subtext">1.3消息通知</p>
          <p class="subtext">您知悉并同意，您在本平台提供的姓名、手机号、公司名称、统一社会信用代码、职位、电子邮箱会用于为您发送多类通知，通知的具体类型包括用户消息告知、身份验证、安全验证等。</p>
          <p class="subtext">1.4 用户使用体验调研</p>
          <p class="subtext">为了了解您对本平台的使用体验，我们会向您发送用户使用体验调研通知。如果您接受调研的，您需要向我们提供【姓名、企业、职位、联系方式、使用体验、邮箱等】信息。</p>
          <p class="subtext">1.5 个性化推送</p>
          <p class="subtext">我们可能会向在前述过程中收集的手机号码通过短信、电话的方式，为您提供您可能感兴趣的服务、功能或活动等商业性信息。如您不愿接受这些信息，您可以直接与我们联系进行退订。 </p>
          <p class="subtext">1.6 运营与安全保障 </p>
          <p class="subtext">我们致力于为您提供安全、可信的产品与使用环境，提供优质而可靠的服务与信息是我们的核心目标。为了维护服务的正常运行，保护您或其他用户或公众的合法利益免受损失，我们会收集用于维护产品或服务安全稳定运行的必要信息，例如发现、处置产品或服务的故障。 </p>
          <p class="subtext">1.6 收集、使用个人信息目的变更 </p>
          <p class="subtext">请您了解，随着我们业务的发展，我们可能会对本平台的功能和提供的服务有所调整变化。原则上，当新功能或服务与我们当前提供的功能或服务相关时，收集与使用的个人信息将与原处理目的具有直接或合理关联。在与原处理目的无直接或合理关联的场景下，我们收集、使用您的个人信息，会再次按照法律法规及国家标准的要求以页面提示、交互流程、协议确认方式另行向您进行告知说明，并征得您的同意。 </p>
          <p class="subtext">1.6 征得授权同意的例外 </p>
          <p class="subtext">请您理解，在下列情形中，根据法律法规及相关国家标准，我们收集和使用您的个人信息不必事先征得您的授权同意：
          <br/>a.与我们履行法律法规规定的义务相关的； 
          <br/>b.与国家安全、国防安全直接相关的； 
          <br/>c.与公共安全、公共卫生、重大公共利益直接相关的； 
          <br/>d.与刑事侦查、起诉、审判和判决执行等直接相关的； 
          <br/>e.出于维护您或他人的生命、财产等重大合法权益但又很难得到本人授权同意的； 
          <br/>f.您自行向社会公众公开的个人信息； 
          <br/>g.根据个人信息主体要求签订和履行合同所必需的； 
          <br/>h.从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道； 
          <br/>i.用于维护软件及相关服务的安全稳定运行所必需的，例如发现、处置软件及相关服务的故障； 
          <br/>j.为开展合法的新闻报道所必需的； 
          <br/>k.为学术研究机构，基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的； 
          <br/>l.法律法规规定的其他情形。 
          <br/>特别提示您注意，如信息无法单独或结合其他信息识别到您的身份，其不属于法律意义上您的信息；当您的信息可以单独或结合其他信息识别到您的身份时或我们将无法与任何特定个人信息建立联系的数据与其他您的信息结合使用时，这些信息在结合使用期间，将作为您的信息按照本隐私政策处理与保护。</p>
          <p class="subtext">（二）我们如何使用您的个人信息</p>
          <p class="subtext">2.1为了向您提供服务，我们会向您发送信息、通知或与您进行业务沟通，包括但不限于为保证服务完成所必须的验证码、使用服务时所必要的推送通知。</p>
          <p class="subtext">2.2用于数据分析，以便向您提供更加优质的产品和/或服务；我们更加了解您如何接入和使用我们的产品和/或服务，从而针对性地回应您的个性化需求，例如语言设定、位置设定、个性化的帮助服务和指示，或对您和其他用户作出其他方面的回应。</p>
          <p class="subtext">2.3我们可能以个人信息统计数据为基础，设计、开发、推广全新的产品及服务；我们会对我们的服务使用情况进行统计，并可能会与公众或第三方分享这些统计信息，但这些统计信息不包含您的任何身份识别信息。</p>
          <p class="subtext">2.4为提高您使用我们及我们关联公司、合作伙伴提供服务的安全性，确保操作环境安全与识别账户异常状态，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或我们相关协议、规则的情况，我们可能使用您的账户开户信息、并整合设备信息、有关网络日志以及我们关联公司、合作伙伴合法分享的信息，进行身份验证、安全事件的检测及防范，并依法采取必要的记录、审计、分析、处置措施。</p>
          <p class="subtext">2.5软件认证或管理软件升级。</p>
          <p class="subtext">2.6让您参与有关我们产品和服务或通过我们的产品和服务发起的调查，是否参与调查将由您全权决定，并且由您自行选择提供哪些信息。</p>
          <p class="subtext">2.7出于安全、合法调查等目的，我们可能使用您的数据做数据汇总、分析、挖掘（包括商业化利用），但这些信息都采用匿名的方式，不能识别您的身份。</p>
          <p class="subtext">2.8如超出收集个人信息时所声称的目的，或者在超出具有直接或合理关联的范围使用个人信息前，我们会再次向您告知并征得您的明示同意。</p>
          <p class="subtext">2.9为了让您有更好的体验、改善我们的产品和服务或您同意的其他用途，在符合相关法律法规的前提下，我们可能将通过我们的某一项产品和服务所收集的信息，以汇集信息或者个性化的方式，用于我们的其他产品和服务。例如，在您使用我们的一项产品或服务时所收集的信息，可能在另一产品或服务中用于向您提供特定内容，或向您展示与您相关的、而非普遍推送的信息。如果您不希望收到这些信息，可以通过【联系我们】取消。</p>
          <p class="subtext">2.10请您注意，除非您删除或撤回同意，您在使用我们的产品和服务时所提供的所有个人信息，将在您使用我们的产品和服务期间持续授权我们在符合本政策的范围内使用。在您注销账户后，我们将根据您的要求删除您的个人信息，但法律法规另有规定的除外。</p>
          <p class="subtext">二. 我们如何使用Cookie和同类技术</p>
          <p class="subtext">为保证网站和产品正常运行，为帮助您获得更轻松的访问体验，并向您推荐您可能感兴趣的信息，我们会在您的计算机或移动设备上存储名为Cookie的小数据文件。Cookie通常包含标识符、站点名称以及一些号码及字符。借助于Cookie，网站能够存储您的偏好等数据，以此帮助您免去重复填写个人信息、输入历史搜索内容等繁琐步骤；同时我们还可能利用上述技术为您推荐、展示、推送您感兴趣的内容。您可根据自己的偏好管理或删除Cookie，您也可以清除计算机上保存的所有 Cookie。我们不会将 Cookie 用于本政策所述目的之外的任何用途。大部分网络浏览器都设有阻止Cookie的功能，您可以通过浏览器设置选项清除计算机上保存的所有Cookie，但如果您这么做，可能会导致您在每一次访问我们的网站时都需要亲自更改用户设置。</p>
          <p class="subtext">三. 我们如何共享、转让、公开披露您的个人信息 </p>
          <p class="subtext">3.1原则
            <br/>a.合法原则：与合作方合作过程中涉及数据使用活动的，必须具有合法目的、符合法定的合法性基础。如果合作方使用信息不再符合合法原则，则其不应再使用你的个人信息，或在获得相应合法性基础后再行使用。 
            <br/>b.正当与最小必要原则：数据使用必须具有正当目的，且应以达成目的必要为限。 
            <br/>c.安全审慎原则： 我们将审慎评估合作方使用数据的目的，对这些合作方的安全保障能力进行综合评估。对我们与之共享个人信息的公司、组织和个人，我们会与其签暑严格的数据保护协定，要求他们按照我们的说明、本政策以及其他任何相关的保密和安全措施来处理个人信息。我们会对合作方获取信息的软件工具开发包（SDK）、应用程序接口（API）进行严格的安全监测，以保护数据安全。</p> 
          <p class="subtext">3.2共享
            <br/>1. 未经您的同意，我们不会共享您的个人信息，除非共享的个人信息是匿名化处理后的信息，且共享第三方无法重新识别此类信息的自然人主体。如果第三方使用信息的目的超越原授权同意范围，他们需要重新征得您的同意。
            <br/>2.在法定情形下的共享：我们可能会根据法律法规规定、诉讼争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息。
            <br/>3.与关联公司间共享：为便于我们基于关联账号共同向您提供服务，推荐您可能感兴趣的信息或保护我们关联公司或其他用户或公众的人身财产安全免遭侵害，您的个人信息可能会与我们的关联公司共享。我们只会共享必要的个人信息，如果我们共享您的个人敏感信息或关联公司改变个人信息的使用及处理目的，将再次征求您的授权同意。
            <br/>4.与授权合作伙伴共享：仅为实现本政策中的目的，我们的某些服务将由我们和授权合作伙伴共同提供。我们可能会与合作伙伴共享您的某些个人信息，以提供更好的服务和用户体验。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息我们的合作伙伴无权将共享的个人信息用于与产品或服务无关的其他用途。</p>
          <p class="subtext">目前，我们的授权合作伙伴包括以下类型：</p>
          <p class="subtext">（1）实现功能或服务的数据使用
            <br/>a.根据《稿真无忧平台服务合同》，我们会利用电话、短信或邮件、第三方软件等，为您推送业务数据消息。
            <br/>b.当您使用由我们的合作方提供的功能，或者当软件服务提供商、智能设备提供商、系统服务提供商与我们联合为你提供的服务时，我们可能会与其使用为实现业务目的所必需的信息，进行综合统计，以便更好地为您服务。 </p>
          <p class="subtext">（2）实现安全与分析统计的数据使用
            <br/>a.保障使用安全：我们非常重视帐号、服务及内容安全，为保障您和其他用户的帐号与财产安全，使您和我们的正当合法权益免受不法侵害，我们的合作方可能会使用必要的设备、帐号及日志信息。 
            <br/>b.分析产品情况：为分析我们产品的使用和表现情况，我们的合作方可能需要使用该产品使用情况（崩溃、闪退）、设备标识信息、应用总体安装使用情况等信息。</p>
          <p class="subtext">3.3转让</p>
          <p class="subtext">我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
            <br/>1.在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息。
            <br/>2.在涉及合并、收购或破产清算或类似的交易或者解散、破产、清算时，如涉及到个人信息转让，我们将在转移前通知您，并告知您接收方的名称或者姓名、联系方式、处理目的、处理方式和个人信息的种类，我们会在要求新的持有您个人信息的公司、组织继续受本政策的约束，否则我们将要求该公司、组织重新向您征求同意。</p>
          <p class="subtext">3.4公开披露</p>
          <p class="subtext">我们不会对您的个人信息进行公开披露，但以下情况除外：
            <br/>1.获得您的明确同意后；
            <br/>2.基于法律的披露：在法律、法律程序、诉讼或有关政府主管部门强制性要求的情况下，我们会公开披露您的信息。</p>
          <p class="subtext">3.5 依法豁免征得同意提供、公开的个人信息</p>
          <p class="subtext">在下列情形中，根据法律法规及国家标准，我们共享、转让、公开披露您的个人信息无需征得您的授权同意： 
            <br/>a.为订立、履行你作为一方当事人的合同所必需，或者按照依法制定的劳动规章制度和依法签订的集体合同实施人力资源管理所必需； 
            <br/>b.为履行法定职责或者法定义务所必需； 
            <br/>c.与国家安全、国防安全直接相关的； 
            <br/>d.与刑事侦查、起诉、审判和判决执行等直接相关的； 
            <br/>e.为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需； 
            <br/>f.为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息； 
            <br/>g.依照相关法律规定处理你自行公开或者其他已经合法公开的个人信息； 
            <br/>h.从合法公开披露的信息中收集个人信息的； 
            <br/>i.法律、行政法规规定的其他情形。</p>

          <p class="subtext">四.我们如何存储个人信息 </p>
          <p class="subtext">4.1 存储地点 </p>
          <p class="subtext">我们依照法律法规的规定，将在境内运营过程中收集和产生的您的个人信息存储于中华人民共和国境内（为本政策目的，不包括香港、澳门和台湾）。目前，我们不会将上述信息传输至境外，如果我们向境外传输，会严格遵守中国的相关法律、监管政策，并会遵循相关国家规定或者征求您的同意。 </p>
          <p class="subtext">4.2 存储期限</p>
          <p class="subtext">我们仅在为提供我们服务之目的所必需的期间和法律法规规定的最全期限内保留您的个人信息，例如： </p>
          <p class="subtext">手机号码、电子邮箱：当您使用手机号码、电子邮箱申请账户登录时，我们需要持续保留您的手机号码、电子邮箱，以便于向您提供正常的服务、应对您可能的查询和客诉并保障您的帐号和系统安全。 </p>
          <p class="subtext">如果您注销帐户、主动删除个人信息或超出必要的期限后，我们将对您的个人信息进行删除处理，但以下情况除外： 
            <br/>A．遵从法律法规有关信息留存的要求（例如：《电子商务法》规定：商品和服务信息、交易信息保存时间自交易完成之日起不少于三年）。 
            <br/>B．出于财务、审计、争议解决等目的需要合理延长期限的。 </p>
          
          <p class="subtext">五.我们如何保护个人信息的安全 
            <br/>5.1我们非常重视您个人信息的安全，将努力采取合理的安全措施（包括技术方面和管理方面）来保护您的个人信息，防止您提供的个人信息被不当使用或未经授权的情况下被访问、公开披露、使用、修改、损坏、丢失或泄漏。
            <br/>5.2我们会使用不低于行业同行的加密技术、匿名化处理及相关合理可行的手段保护您的个人信息，并使用安全保护机制防止您的个人信息遭到恶意攻击。 
            <br/>5.3我们会建立专门的安全部门、安全管理制度、数据安全流程保障您的个人信息安全。我们采取严格的数据使用和访问制度，确保只有授权人员才可访问您的个人信息，并适时对数据和技术进行安全审计。 
            <br/>5.4尽管已经采取了上述合理有效措施，并已经遵守了相关法律规定要求的标准，但请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全，我们将尽力确保您提供给我们的个人信息的安全性。
            <br/>5.5您知悉并理解，您接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。因此，我们强烈建议您采取积极措施保护个人信息的安全，包括但不限于使用复杂密码、定期修改密码、不将自己的帐号密码及相关个人信息透露给他人。
            <br/>5.6我们会制定应急处理预案，并在发生用户信息安全事件时立即启动应急预案，努力阻止这些安全事件的影响和后果扩大。一旦发生用户信息安全事件（泄露、丢失）后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已经采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施。我们将及时将事件相关情况以推送通知、邮件、信函、短信及相关形式告知您，难以逐一告知时，我们会采取合理、有效的方式发布公告。同时，我们还将按照相关监管部门要求，上报用户信息安全事件的处置情况。 
            <br/>5.7您一旦离开本平台及相关服务，浏览或使用其他网站、服务及内容资源，我们将没有能力和直接义务保护您在我们及相关服务之外的软件、网站提交的任何个人信息，无论您登录、浏览或使用上述软件、网站是否基于"我们"的链接或引导。</p>
          
          <p class="subtext">六.您如何访问和管理您的个人信息</p> 
          <p class="subtext">我们非常重视您对个人信息的管理，并尽全力保护您对于您个人信息的查阅、复制、更正、补充、删除、撤回同意授权、注销帐号、投诉举报以及设置隐私功能等权利，以使您有能力保障您的隐私和信息安全。 </p>
          <p class="subtext">6.1您有权查询、更正或补充您的信息。</p>
          <p class="subtext">您可以通过以下方式自行进行：登录本平台账户，进入“账户设置”，点击“账户信息”后查询本人单个用户账户相关信息；企业账户通过“用户管理”进行单个用户账户相关信息的编辑与变更。</p>
          <p class="subtext">6.2 改变或撤回授权范围 </p>
          <p class="subtext">6.2.1改变或撤回敏感权限设置：您可以联系我们以改变同意范围或撤回您的授权。撤回授权后我们将不再收集与该权限相关信息。 </p>
          <p class="subtext">6.2.2改变或撤回授权的信息处理：特定的业务功能和服务将需要您的信息才能得以完成，当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的功能和服务，也不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响我们此前基于您的授权而开展的个人信息处理。 </p>
          <p class="subtext">6.3删除您所属企业和/或您个人的信息。</p>
          <p class="subtext">以下情形中，您可通过我们公示的联系方式向我们提出删除个人信息：
            <br/>（1）如果我们处理个人信息的行为违反法律法规；
            <br/>（2）如果我们收集、使用您的个人信息，却未征得您的同意；
            <br/>（3）如果我们处理个人信息的行为违反了与您的约定；
            <br/>（4）如果您不再使用我们的产品或服务，或您注销了账户；
            <br/>（5）如果我们不再为您提供产品或服务。
            <br/>收到您的删除请求后，我们会根据您的要求进行删除处理并在十五个工作日内向您反馈结果（法律法规要求必须保留的数据除外）。</p>

          <p class="subtext">6.4 注销帐号 </p>
          <p class="subtext">您可以通过企业管理账户，通过“用户管理”-“用户删除“注销单个用户账户，或者联系我们，对企业账户进行注销。为了方便您的企业重新启动使用，我们将为您的企业保存信息及记录365个自然日。</p>
          <p class="subtext">6.5 访问隐私政策 </p>
          <p class="subtext">您可以在本平台页面底部点击“隐私协议”，查看本协议全部内容。</p>
          <p class="subtext">6.6 停止运营并向您告知 </p>
          <p class="subtext">如我们停止运营，我们将及时停止收集您所在企业信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，并对所持有的您的信息进行删除。</p>
          <p class="subtext">除上述外，如果您或其他有权主体对相关法律法规赋予的个人信息权利的行使，有任何主张、要求或者疑问，您可以通过发送邮件至pdm.service@greatdata.com.cn与我们联系，我们将尽快审核所涉问题，并在验证您的身份后的十五个工作日内回复。</p>
          <p class="subtext">七. 本隐私政策的修订</p>
          <p class="subtext">我们的隐私政策可能变更，未经您明确同意，我们不会限制您按照本隐私政策所应享有的权利。有关隐私政策的任何变更，我们会通过本平台公示等方式提前通知您。对于修订或更新后的隐私政策，当收集使用个人信息目的、方式、范围变化时，我们会再次征求您的同意。</p>
          <p class="subtext">我们建议您定期查看隐私政策，以获取有关我们隐私政策的最新信息。若您不同意修改后的隐私政策，您有权并应立即停止使用我们的产品和/或服务。如果您继续使用我们的产品和/或服务，则视为您接受我们对本政策相关条款所做的修改。</p>
          <p class="subtext">八. 联系我们</p>
          <p class="subtext">如您对本政策内容有任何疑问、意见或建议，或发现您的个人信息可能被泄露或您觉得需要撤回、更正、删除您的个人信息授权，您可以发邮件到【+86 10 6435 6368-1702】与我们联系。您也可以向我们邮寄您的投诉或举报材料，我们的邮寄地址是：【北京市朝阳区酒仙桥路14号50号楼A1区4门2层127室】。</p>
          <p class="subtext">如果您对我们的回复不满意，特别是您认为我们的个人信息处理行为损害了您的合法权益，您还可以通过一点通所在地有管辖权的法院提起诉讼来寻求解决方案。本隐私政策适用中华人民共和国大陆地区法律。</p>
        </div>
      </div>
    </div>
    <p-footer></p-footer>
  </div>
</template>

<script type="text/ecmascript-6">
  import PHeader from 'components/header/header'
  import PFooter from 'components/footer/footer'

  export default {
    data() {
      return {}
    },
    /* eslint-disable no-undef */
    created() {
    },
    methods: {},
    watch: {},
    components: {
      PHeader,
      PFooter
    }
  }
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
  .info-wrapper
    width 100%
    min-width 1270px
    .banner
      padding-top: 0.35rem;
      position relative
      text-align: center
      width: 100%
      .banner-title
        position absolute
        top 1.1rem
        left 51%
        transform: translateX(-50%)
        font-size: 0.23rem;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 0.44rem;
    .info-container
      width 100%
      box-sizing border-box
      .info-content-box
        background: #FFFFFF;
        .info-bottom-box
          font-size: 0.08rem;
          font-weight: 400;
          color: #666666;
          text-align: center
          margin-top: 0.8rem
          margin-bottom: 0.2rem
        .info-item-box
          color #333
          text-align left
          box-sizing border-box
          padding .45rem 2.29rem .21rem 2.29rem
          .text
            font-size: 0.23rem;
            font-weight: bold;
            color: #1A1A1A;
            letter-spacing: 1px;
            margin-top: 0.3rem;
          .subtext
            font-size: 0.12rem;
            font-weight: 400;
            color: #666666;
            line-height: 0.24rem
            margin-top: 0.2rem
            letter-spacing: 1px;
            text-indent: 2em
        .info-contact-box
          padding-bottom 0.3rem
          text-align center
          color #333
</style>
